<template>
    <b-container fluid position-relative class="p-4 h-100">
        <h2>Die Grundlagen</h2>
        <h3>Navigationsleiste</h3>
        <p>Am oberen Ende der Seite findet sich immer die Navigationsleiste. Diese ermöglich den schnellen Wechsel
            zwischen den folgenden wichtigen Seiten:</p>
        <ul>
            <li><b>Einsätze:</b> Übersicht aller Einsätze, Zuordnung von Fahrzeugen. Die Blase rechts zeigt die Anzahl der aktiven Einsätze.</li>
            <li><b>Karte:</b> Darstellung von allen Einsätzen auf einer Karte</li>
            <li><b>Kontakte:</b> Telefonbuch-Funktion mit Suche</li>
            <li><b>Verlauf:</b> Alles übergreifender Änderungsverlauf</li>
            <li><b>Vorschläge/Probleme:</b> Hier können Änderungswünsche gemeldet werden</li>
            <li><b>Hilfe:</b> Diese Seite</li>
            <li><b>Impressum:</b> gesetzlich vorgeschriebenes Impressum</li>
        </ul>

        <p>Weiterhin findet sich der aktuelle Verbindungsstatus und der Benutzername: </p>
        <p>Verbunden: Nutzer@sonderlage.fwtools.org</p>
        <p>Mit Klick auf diesen Verbindungsstatus kann der Nutzername geändert werden.</p>

        <p>Ganz rechts findet sich außerdem ein Button um einen neuen Einsatz anzulegen.</p>
        <h3>Einsatz anlegen</h3>
        <p>Im Produktiv-Betrieb legt das Einsatzdisplay automatisch Einsätze an. Natürlich ist aber auch das manuelle
            Anlegen möglich.
            Hierzu klickt man einfach auf den Button "Neuer Einsatz" in der oberen rechten Ecke:</p>
        <b-btn variant="success" class="mb-2"><span class="oi oi-plus"></span> Neuer Einsatz</b-btn>
        <p>Dieser öffnet die Eingabemaske für den neuen Alarm:</p>
        <b-img src="/help/new_alarm.png" class="screenshot"></b-img>
        <p>Hier können Einsatztyp, Einsatzort, Einsatzdetails und Dringlichkeit/Priorität ausgewählt werden. Zu beachten
            ist, dass das der Einsatzort eine Autovervollständigung besitzt. Diese sucht in dieser Demonstration nach
            Straßen in der Region
            Hohenstein-Ernstthal, Oberlungwitz und Gersdorf. Suchergebnisse werden bei Klick übernommen und rechts in
            der Karte dargestellt.</p>
        <p class="low_prio">Die Suche verhält sich aktuell noch nicht 100% wie gewünscht. Geplant ist eine Anzeige auf
            der Karte bereits vor dem Übernehmen (beim Überfahren mit der Maus). Weiterhin wird das Suchfeld im Falle
            einer späteren Änderung wieder
            komplett geleert.</p>
        <p>Ist die Eingabe abgeschlossen kann mit dem Button "Einsatz anlegen" der Einsatz angelegt werden und die
            Ansicht wechselt zurück zur Einsatz-Liste.</p>
        <h3>Einsatz-Übersicht</h3>
        <p>Die Einsatz-Übersicht ist das Hautp-Werkzeug des Disponenten. Hier können Fahrzeuge zugeordnet werden und
            Schnelleinstellungen vorgenommen werden.
        </p>

        <h4>Ein Alarm im Detail</h4>
        <b-img src="/help/alarm_detail.png" class="screenshot"></b-img>
        <p>Die Einsätze sind je nach Priorität unterschiedlich farblich hinterlegt:</p>
        <b-button class="prio_wichtig">Wichtig</b-button>
        <b-button class="prio_normal">Normal</b-button>
        <b-button class="prio_gering">Geringe Priorität</b-button>
        <b-button class="prio_finished">Einsatz beendet</b-button>

        <p class="mt-3">Weiterhin bekommt jeder Einsatz eine eindeutige Nummer. So soll eine Verwechslung ausgeschlossen
            und eine
            schnellere Kommunikation zwischen den Disponenten möglich werden. Diese Nummer findet sich dabei oben
            links.</p>
        <p>Oben rechts finden sich verschiedene Schnellaktionen:</p>
        <ul>
            <li><b>Details:</b> Öffnet die Detail-Ansicht des entsprechenden Einsatzes</li>
            <li><b>Ende:</b> Der Einsatz wird beendet, alle Fahrzeuge werden automatisch freigegeben</li>
            <li><b>Karte:</b> Zeigt den Einsatz auf der Übersichtskarte</li>
            <li><b>Priorität:</b> Kurz-Menü zum Anpassen der Einsatz-Priorität. Achtung, bei Sortierung mit Priorität
                wird der Einsatz eventuell in der Liste verschoben!
            </li>
        </ul>
        <p>Im unteren Bereich befinden sich die Fahrzeuge die dem Einsatz aktuell zugeteilt sind. Die Arbeit mit diesen
            wird im Folgenden näher erläutert.</p>

        <h4>Fahrzeuge zuweisen/abziehen</h4>
        <p>
            Nachdem wir nun erfolgreich Einsätze anlegen können müssen den Einsätzen natürlich noch Fahrzeuge zuweisen,
            welche die Einsätze dann abarbeiten.
            Hierzu können wir einfach ein Fahrzeug von einer der Organisationen rechts per Drag&Drop auf einen der
            Einsätze ziehen. Es spielt dabei keine Rolle ob das Fahrzeug auf dem unteren oder oberen Bereich abgelegt
            wird:
        </p>
        <b-img src="/help/add_car.png" class="screenshot"></b-img>
        <p>Wird der Einsatz beendet werden alle Fahrzeuge wieder freigegeben und finden sich rechts in der
            entsprechenden Feuerwehr wieder.</p>
        <p>Alternativ kann ein Fahrzeug auch mit dem X auf der rechten Seite wieder entfernt werden. Außerdem kann es
            per Drag&Drop direkt zu einem anderen Einsatz verschoben werden.</p>

        <h3>Dokumentation</h3>
        <p>Um später auch eine Dokumentation zum Einsatzgeschehen zu haben wurde eine Tagebuchfunktion integriert.
            Hierbei gibt es für jeden Einsatz ein seperates Tagebuch.
            Dieses findet sich in der Einsatz-Detail-Ansicht, welche über den blauen "Detail"-Button des jeweiligen
            Einsatzes erreicht werden kann.</p>
        <p>Automatisch Dokumentiert werden:</p>
        <ul>
            <li>Erstellung des Einsatzes</li>
            <li>Änderung der Priorität</li>
            <li>Hinzufügen/Entfernen von Fahrzeugen</li>
            <li>Änderung von Einsatzdaten (in Planung!)</li>
            <li>Ende des Einsatzes</li>
        </ul>
        <p>Eigene Meldungen können über das Eingabefeld unter dem Tagebuch nach belieben hinzugefügt werden. Hierbei
            werden Nutzer und Zeit automatisch erfasst.</p>
        <p class="low_prio">Eine Bearbeitung oder Löschung von Einträgen ist in Planung.</p>

        <h3>Ändern von Einsatzdaten</h3>
        <p>Ebenfalls in der Einsatz-Detail-Ansicht können Einsatzort und Einsatzdetails geändert werden.</p>
        <p class="low_prio">Die GPS-Position des Einsatzes kann aktuell nur auf der Übersichtskarte geändert werden. Eine Änderung des Einsatztyps ist noch nicht möglich.</p>
    </b-container>
</template>

<script>
  export default {
    name: 'app',
    data() {
      return {};
    },
    components: {},
    methods: {}
  }
</script>

<style scoped>
    .screenshot {
        border: 2px solid black;
        width: 70%;
        margin-bottom: 10px;
    }

    .low_prio {
        border: 1px solid grey;
        padding: 10px;
        background: rgba(255, 209, 0, 0.51);
    }

    .prio_wichtig {
        background: #fdd;
        color: black;
        margin-right: 10px;
    }

    .prio_normal {
        background: #fff;
        color: black;
        margin-right: 10px;
    }

    .prio_gering {
        background: #ddd;
        color: black;
        margin-right: 10px;
    }

    .prio_finished {
        background: #999;
        color: whitesmoke;
    }
</style>
