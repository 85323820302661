<template>
    <drop class="drop m-0 m-2 mt-1 w-100" @drop="handleDrop(alarm, ...arguments)">
        <div class="card m-0 p-0" :class="getPrioClass()">
            <div class="card-header">
                <b-row>
                    <div class="col-sm-6 col-lg-7 col-xl-8"><h3><span class="mr-3 badge badge-dark" :class="getAlarmClass()">#{{alarm.id}}</span>{{alarm.name}}<span
                            v-if="alarm.location"> - {{alarm.location}}</span></h3>
                    </div>
                    <div class="col-sm-6 col-lg-5 col-xl-4">
                        <b-button-group class="w-100">
                            <b-button class="col-lg-3 col-md-4 col-sm-6" variant="primary"
                                      :to="{name: 'alarmDetail', params: { id: alarm.id } }"><i class="oi oi-document"></i> Details
                            </b-button>
                            <b-button class="col-lg-3 col-md-4 col-sm-6" v-if='!alarm.finished'
                                      v-on:click='finished(true)' variant="danger"
                                      :block=true>
                                <i class="oi oi-media-stop"></i> Ende
                            </b-button>
                            <b-button class="col-lg-3 col-md-4 col-sm-6" v-if='alarm.finished'
                                      v-on:click='finished(false)' variant="success"
                                      :block=true>
                                <i class="oi oi-media-play"></i> Starten
                            </b-button>
                            <b-button class="col-lg-3 col-md-4 col-sm-6" variant="warning"
                                      :to="{name: 'mapWithCoords', params: {lat: alarm.lat, lon: alarm.lon}}">
                                <i class="oi oi-map-marker"></i> Karte
                            </b-button>

                            <b-dropdown right class="w-100" text="Priorität" variant="secondary" vertical="true">
                                <b-dropdown-item v-on:click='setPrio("wichtig")' href="#">Wichtig</b-dropdown-item>
                                <b-dropdown-item v-on:click='setPrio("normal")' href="#">Normal</b-dropdown-item>
                                <b-dropdown-item v-on:click='setPrio("gering")' href="#">Gering</b-dropdown-item>
                            </b-dropdown>
                        </b-button-group>
                    </div>
                </b-row>
            </div>
            <div v-if="!compact" class="card-body pb-1">
                <dl class="row m-0 p-0">

                    <template v-if="alarm.address">
                        <dt class="col-sm-2 p-1">Straße</dt>
                        <dd class="col-sm-4 p-1">{{alarm.address.road}} {{alarm.address.house_number}}</dd>
                        <dt class="col-sm-2 p-1">Ort</dt>
                        <dd class="col-sm-4 p-1">{{alarm.address.city_district || alarm.address.town ||
                            alarm.address.village}}
                        </dd>
                    </template>
                    <template v-if="alarm.address === undefined">
                        <dt class="col-sm-2 p-1">E-Ort</dt>
                        <dd class="col-sm-10 p-1">{{alarm.location}}</dd>
                    </template>

                    <dt class="col-sm-2 p-1">Meldung</dt>
                    <dd class="col-sm-4 p-1">{{alarm.detail}}</dd>
                    <dt class="col-sm-2 p-1">Startzeit</dt>
                    <dd class="col-sm-4 p-1">{{getTimeFromDate(alarm.begin)}}</dd>
                    <!--<dt class="col-sm-3 p-1">Nummer</dt>
                    <dd class="col-sm-9 p-1">{{alarm.id}}</dd>-->

                    <!--
                    <dt class="col-sm-3 p-1">Verlauf</dt>
                    <dd class="col-sm-9 p-1">
                        <dl class="row">
                            <template v-for="msg in alarm.msgs">
                                <dt class="col-sm-3">{{getTimeFromDate(msg.time)}}</dt>
                                <dl class="col-sm-9">{{msg.text}}</dl>
                            </template>
                        </dl>
                    </dd>
                    -->
                </dl>

            </div>
            <div v-if="!alarm.finished" class="card-footer">
                <div class="container-fluid m-0 p-0">
                    <div class="row rounded m-0 p-0">
                        <div class="cars_empty" v-if="alarm.cars.length === 0">
                            Hier Fahrzeuge ablegen
                        </div>
                        <drag v-for="car in alarm.cars"
                              class="drag col-xl-3 col-lg-4 col-sm-6 p-1"
                              :key="car.home + ' ' + car.name"
                              :class="{ [car]: true }"
                              :transfer-data="{ item: car, alarm: alarm, example: 'alarm.cars' }">
                            <Unit v-bind:car="car" @remove_car="removeCar"/>
                        </drag>
                    </div>
                </div>
            </div>
        </div>
    </drop>
</template>

<script>
  import Unit from './Unit.vue'
  import {Drag, Drop} from 'vue-drag-drop';
  import io from 'socket.io-client';

  export default {
    props: {
      'alarm': Object,
      'compact': Boolean
    },
    components: {
      Unit, Drag, Drop
    },
    data() {
      return {
        options: {
          // dropzoneSelector: 'ul',
          // draggableSelector: 'li',
          // excludeOlderBrowsers: true,
          // showDropzoneAreas: true,
          // multipleDropzonesItemsDraggingEnabled: true,
          // onDrop(event) {},
          //onDragstart(event) {
          //   event.stop();
          //},
          onDragend(event) {
            //    alert(JSON.stringify(event))
          },
          onDrop(event) {
            //   alert(JSON.stringify(event))
          }
        },
        selected: 'radio1',
        btnOptions: [
          {text: 'E', value: 'radio1'},
          {text: 'Z', value: 'radio2'},
          {text: 'A', value: 'radio3'}
        ]
      }
    },
    methods: {
      handleDrop(toAlarm, data) {
        console.log(JSON.stringify(data));
        const fromAlarm = data.alarm;
        if (fromAlarm) {
          data.item.status = 4;
          toAlarm.cars.push(data.item);
          fromAlarm.cars.splice(fromAlarm.cars.indexOf(data.item), 1);
          toAlarm.msgs.push({time: new Date().getTime(), user: this.$root.userName, text: data.item.name + "(" + data.item.home + ") hinzugefügt"});
          fromAlarm.msgs.push({time: new Date().getTime(), user: this.$root.userName, text: data.item.name + "(" + data.item.home + ") entfernt"});
          toAlarm.cars.sort((a, b) => a > b);
        }
        this.$root.logEvent(`${data.item.name} (${data.item.home}) zu Einsatz ${this.alarm.id} hinzugefügt.`);
        this.$root.handleUpdate()
      },
      getTimeFromDate(time) {
        let date = new Date(time);
        return date.toLocaleTimeString('de-DE', {
          hour12: false,
          hour: "numeric",
          minute: "numeric"
        });
      },
      setPrio(prio) {
        console.log("Prio: " + prio);
        this.alarm.prio = prio;
        this.alarm.msgs.push({
          time: new Date().getTime(),
          user: this.$root.userName,
          text: `Priorität in ${prio} geändert.`
        });
        this.$root.logEvent(`Einsatz ${this.alarm.id} auf Priorität ${prio} geändert.`);
        this.$root.handleUpdate()
      },
      getPrioClass() {
        if (this.alarm.finished)
          return "prio_finished";

        if (this.alarm.prio)
          return "prio_" + this.alarm.prio;
      },
      finished(fin_val) {
        this.alarm.finished = fin_val;
        if (fin_val) {
          this.endAlarm(this.alarm);
          this.$root.logEvent(`Einsatz ${this.alarm.id} beendet.`);
        } else {
          this.$root.logEvent(`Einsatz ${this.alarm.id} wieder gestartet.`);
        }
        this.$root.handleUpdate()
      },
      removeCar(car) {
        this.$root.removeCar(car, this.alarm);
      },
      endAlarm(alarm) {
        console.log("removing cars from alarm: " + alarm.cars.length);
        alarm.cars.slice(0).forEach(car => { // slice(0) == clone
          console.log("removing:" + car.name);
          this.removeCar(car, alarm);
        });
      },
      //TODO: potential duplication with map
      getAlarmClass(){
        if(this.alarm.finished)
          return 'alarm_done';
        else if(this.alarm.cars.length > 0) {
          return 'alarm_active';
        } else {
          return 'alarm_todo';
        }
      }
    },
  }
</script>

<style scoped>
    @import "../../node_modules/open-iconic/font/css/open-iconic-bootstrap.css";

    .drag-wrapper {
        display: flex;
        justify-content: center;
    }

    ul {
        display: flex;
        flex-direction: column;
        padding: 3px !important;
        min-height: 30px;
        width: 100%;
        float: left;
        list-style-type: none;
        overflow-y: auto;
        border: 2px solid #888;
        border-radius: 0.2em;
        background: #eee;
        color: #555;
        margin-right: 5px;
    }

    /* drop target state */
    ul[aria-dropeffect="move"] {
        border-color: #68b;
        background: #fff;
    }

    /* drop target focus and dragover state */
    ul[aria-dropeffect="move"]:focus,
    ul[aria-dropeffect="move"].dragover {
        outline: none;
        box-shadow: 0 0 0 1px #fff, 0 0 0 3px #68b;
    }

    /* draggable items */
    li {
        display: block;
        list-style-type: none;
        margin: 0 0 2px 0;
        padding: 0.2em 0.4em;
        border-radius: 0.2em;
        line-height: 1.3;
    }

    li:hover {
        box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
    }

    /* items focus state */
    li:focus {
        outline: none;
        box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
    }

    /* items grabbed state */
    li[aria-grabbed="true"] {
        background: #5cc1a6;
        color: #fff;
    }

    @keyframes nodeInserted {
        from {
            opacity: 0.2;
        }
        to {
            opacity: 0.8;
        }
    }

    .item-dropzone-area {
        height: 2rem;
        background: #888;
        opacity: 0.8;
        animation-duration: 0.5s;
        animation-name: nodeInserted;
    }

    .prio_wichtig {
        background: #fdd;
    }

    .prio_gering {
        background: #ddd;
    }

    .prio_finished {
        background: #999;
        color: whitesmoke;
    }

    .cars_empty {
        font-size: 1.3em;
        font-weight: bolder;
        margin-left: 0;
        margin-right: 0;
        color: rgba(50, 50, 50, 0.3);
        min-height: 40px;
    }

    .alarm_todo {
        background-color: #ae1400;
    }

    .alarm_active {
        background-color: #c9c900;
        color: black;
    }

    .alarm_done {
        background-color: #66971c;
    }
</style>