<template>
    <div class="container-fluid position-relative p-4 h-100">
            <h2>Verlauf</h2>
            <b-table striped hover :items="this.$root.log" sort-by="time" sort-desc :fields="log_table_fields"/>
    </div>
</template>

<script>
  export default {
    name: 'app',
    data() {
      return {
        log_table_fields: [
          {
            key: "time", label: "Zeit", formatter: value => {
              const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              };
              return new Date(value).toLocaleDateString('de-DE', options)
            },
            class: "col_time"
          },
          {key: "userName", label: "Benutzer", class: "col_user"},
          {key: "msg", label: "Aktion", class: "col_msg"},
        ],
      };
    },
    components: {
    },
    methods: {
    },
  }
</script>

<style scoped>

</style>
