<template>
    <div class="container-fluid position-relative p-4 h-100">
        <h1>Telefon-Liste</h1>
        <b-form class="mb-4">
            <b-form-input id="tel_search"
                          type="text"
                          placeholder="Suche"
                          v-model="phone_filter">
            </b-form-input>
        </b-form>
        <b-table :items="phone_list" :filter="phone_filter"></b-table>
    </div>
</template>

<script>

  export default {
    name: 'app',
    data() {
      return {
        phone_list: [
          {"kontakt": "Leitstelle Zwickau", "telefon": "09999/1234567", "fax": "09999/1234569"},
          {"kontakt": "Leitstelle Chemnitz", "telefon": "09999/1234567", "fax": "09999/1234569"},
        ],
        phone_filter: ""
      };
    },
    components: {

    },
    methods: {
      getLatLon(alarm) {
        console.log(alarm.detail + " " + L.latLng(alarm.lat, alarm.lon));
        return L.latLng(alarm.lat, alarm.lon);
      },
      updateAlarmPosition(alarm, event) {
        //console.log(JSON.stringify(event))

        const latLng = event.target._latlng;
        alarm.lat = latLng.lat;
        alarm.lon = latLng.lng;
        this.handleUpdate();
      },
      showAlarmOnMap(alarm) {
        this.center = this.getLatLon(alarm);
        this.page = "map";
      },
      getAlarmColor(alarm) {
        if (alarm.finished)
          return '#0f0';

        switch (alarm.prio) {
          case "wichtig":
            return '#f00';
          case "normal":
            return '#00f'
          case "gering":
            return '#ccc'
        }

        return '#000'
      },
      manualAlarm(event) {
        this.manAlarmEvent = event;
        this.page = "new_alarm";
      },
      showLongText() {
        this.showParagraph = !this.showParagraph;
      },
      popupClick() {
        alert('Popup Click!');
      },
      handleUpdate() {
        console.log('Update incomming!');
        this.socket.emit('SEND_UPDATE', {alarms: this.alarms, orgs: this.orgs, log: this.log});
      },
      changePage(newPage) {
        console.log("newPage: " + newPage)
        this.page = newPage;
      },
      createAlarm(alarm) {
        this.page = "alarms";
        this.manAlarmEvent = undefined;
        this.logEvent(this.userName + " hat Einsatz " + alarm.id + " angelegt")
        this.socket.emit('CREATE_ALARM', alarm);
      },
      showAlarmDetail(alarm) {
        this.page = "alarm_detail";
        this.detail_alarm = alarm;
      },
      removeCar(data) {
        let car = data.car;
        let alarm = data.alarm;
        car.status = 2;
        this.orgs.filter(org => org.id === car.home)[0].cars.push(car);
        alarm.cars = alarm.cars.filter(c => !(c.home === car.home && c.name === car.name)); //or splicing?
        this.handleUpdate();
      },
      endAlarm(alarm) {
        console.log("removing cars from alarm: " + alarm.cars.length);
        alarm.cars.slice(0).forEach(car => { // slice(0) == clone
          console.log("removing:" + car.name);
          this.removeCar({car, alarm});
        });
      },
      getMapIconForAlarm(alarm) {
        switch (alarm.prio) {
          case "normal":
            return undefined;
        }
      },
      logEvent(msg) {
        console.log(this.userName + ": " + msg)
        this.log.push({
          time: new Date(),
          userName: this.userName,
          msg: msg
        })
      }
    },
  }
</script>

<style scoped>

</style>