<template>
    <div class="container-fluid position-relative p-4 h-100">
        <b-row>
            <b-col cols="6">
                <h1>ToDo-Liste</h1>
                <ul>
                    <li>Generierung eines hübschen Einsatz-Abschlussberichts</li>
                    <li>Aufgabenliste?</li>
                    <li>Sat-Karte?</li>
                    <li>Hydranten integrieren</li>
                    <li>Wichtige Gebäude integrieren -> Verlinkung zu Daten?</li>
                    <li>Evtl. Login?</li>
                    <li>Senden von Einsätzen ans Alarmdisplay für ausrückende Kräfte?</li>
                    <li>Einsatzort-Koordinaten ändern in Alarm-Details</li>
                    <li>Nutzer-Einstellungen der Alarm-Ansicht lokal speichern</li>
                    <li>Alarm-Tagebuch bearbeitbar</li>
                    <li>Fahrzeuge bearbeitbar, Mannschaft hinterlegen, Mannschaft anzeigen?</li>
                    <li>Mehrere Lagen?</li>
                    <li>Bessere Kennzeichnung der Ortschaften in der Alarm-Übersicht</li>
                    <li>Verlinkung von Einsätzen im Verlauf, Filter nach Nutzer?</li>
                </ul>
            </b-col>
            <b-col cols="6">
                <h1>Bereits erledigt</h1>
                <ul>
                    <li>Einsätze wieder besser in Karte integrieren -> Tooltip -> Detail-Button</li>
                    <li>Telefonverzeichnis</li>
                    <li>Bei Beendigung eines Einsatzes Fahrzeuge freigeben</li>
                    <li>Fahrzeug-Status setzbar machen - Ersetzt durch "Fahrzeug freigeben"</li>
                    <li>Einsatzdaten bearbeiten (teilweise)</li>
                    <li>Automatisches Einpflegen von eingehenden Alarmen via Einsatzdisplay</li>
                    <li>Benutzernamen</li>
                </ul>
            </b-col>
        </b-row>

        <b-jumbotron header="Weitere Ideen?" lead="Einfach mal ne Mail schreiben ;-)">
            <a href="mailto:sonderlage@fwtools.org">sonderlage@fwtools.org</a>
        </b-jumbotron>
    </div>
</template>

<script>

  export default {
    name: 'app',
    data() {
      return {

      };
    },
  }
</script>

<style scoped>

</style>