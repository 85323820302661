<template>
    <b-container fluid class="position-relative p-4 h-100">
        <b-card v-if="alarm" :class="getPrioClass()">
            <b-card-title>
                <b-row>
                    <div class="col-8"><h3><span class="mr-3 badge badge-dark">#{{alarm.id}}</span>  {{alarm.name}}</h3></div>
                    <div class="col-4 w-100">
                        <div class="btn-group w-100" role="group">
                            <b-button-group class="w-100">
                                <b-button class="col-lg-3 col-md-4 col-sm-6" v-if='!alarm.finished'
                                          v-on:click='finished(true)' variant="danger"
                                          :block=true>
                                    <i class="oi oi-media-stop"></i> Ende
                                </b-button>
                                <b-button class="col-lg-3 col-md-4 col-sm-6" v-if='alarm.finished'
                                          v-on:click='finished(false)' variant="success"
                                          :block=true>
                                    <i class="oi oi-media-play"></i> Starten
                                </b-button>
                                <b-button class="col-lg-3 col-md-4 col-sm-6" variant="warning"
                                          :to="{name: 'mapWithCoords', params: {lat: alarm.lat, lon: alarm.lon}}">
                                    <i class="oi oi-map-marker"></i> Karte
                                </b-button>

                                <b-dropdown right class="w-100" text="Priorität" variant="secondary" vertical="true">
                                    <b-dropdown-item v-on:click='setPrio("wichtig")' href="#">Wichtig</b-dropdown-item>
                                    <b-dropdown-item v-on:click='setPrio("normal")' href="#">Normal</b-dropdown-item>
                                    <b-dropdown-item v-on:click='setPrio("gering")' href="#">Gering</b-dropdown-item>
                                </b-dropdown>
                            </b-button-group>
                        </div>
                    </div>
                </b-row>
                <hr/>
            </b-card-title>
            <b-card-body>
                <div class="row">
                    <div class="col-6">
                        <!--<dt class="col-sm-3 p-1">Nummer</dt>
                    <dd class="col-sm-9 p-1">{{alarm.id}}</dd>-->

                        <dt class="col-sm-3 p-1">Startzeit</dt>
                        <dd class="col-sm-9 p-1">
                            {{new Date(alarm.begin).toLocaleDateString()}} - {{new Date(alarm.begin).toLocaleTimeString()}}
                        </dd>

                        <dt class="col-sm-3 p-1">Ort</dt>
                        <dd class="col-sm-9 p-1">
                            <b-form-input v-model="alarm.location" v-on:change="changed"/>
                        </dd>

                        <dt class="col-sm-3 p-1">Koordinaten</dt>
                        <dd class="col-sm-9 p-1">{{alarm.lat}} | {{alarm.lon}}</dd>

                        <dt class="col-sm-3 p-1">Detail</dt>
                        <dd class="col-sm-9 p-1">
                            <b-form-input v-model="alarm.detail" v-on:change="changed"/>
                        </dd>

                        <template v-if="alarm.original">
                            <dt class="col-sm-3 p-1">DME-Nachricht</dt>
                            <dd class="col-sm-9 p-1">{{alarm.original}}</dd>
                        </template>

                        <dt class="col-sm-3 p-1">Fahrzeuge:</dt>
                        <dd class="col-sm-9 p-1">
                            <dl>
                                <ul v-for="car in alarm.cars" :key="car.name">
                                    <li>{{car.name}}</li>
                                </ul>
                            </dl>
                        </dd>
                        <template v-if="alarm.rics">
                            <dt class="col-sm-3 p-1">Alarmierte Schleifen:</dt>
                            <dd class="col-sm-9 p-1">
                                <dl>
                                    <ul v-for="ric in alarm.rics" :key="ric">
                                        <li>{{ric}}</li>
                                    </ul>
                                </dl>
                            </dd>
                        </template>

                    </div>
                    <div class="col-md-6">
                        <l-map
                                :zoom="zoom"
                                :center="center"
                                style="height: 100%">
                            <l-tile-layer
                                    :url="url"
                                    :attribution="attribution"/>
                            <l-marker :lat-lng="marker">
                            </l-marker>
                        </l-map>
                    </div>
                </div>
                <hr/>
                <b-row>
                    <h3 class="col-12">Einsatztagebuch</h3>
                    <dd class="col-sm-12 p-1">
                        <b-table striped hover sticky-header="600px" :items="alarm.msgs" :sort-by="time" :fields="log_table_fields"/>
                        <b-row class="p-3">
                            <b-form-input class="col-10" v-model="newMessage"
                                          type="text"
                                          placeholder="Neue Tagebuchmeldung"
                                          @keydown.enter.native="addNewMessage"></b-form-input>
                            <b-button class="col-2" v-on:click="addNewMessage">Hinzufügen</b-button>
                        </b-row>
                    </dd>
                </b-row>
            </b-card-body>
        </b-card>
    </b-container>

</template>

<script>
  import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet';
  import Unit from '../Unit.vue'

  export default {
    components: {
      Unit,
      LMap,
      LTileLayer,
      LMarker,
      LPopup,
    },
    data() {
      return {
        selected: 'radio1',
        btnOptions: [
          {text: 'E', value: 'radio1'},
          {text: 'Z', value: 'radio2'},
          {text: 'A', value: 'radio3'}
        ],
        zoom: 18,
        newMessage: "",
        url: 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png',
        //url: 'http://192.168.122.146/hot/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        log_table_fields: [
          {
            key: "time", label: "Zeit", formatter: value => {
              const options = {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                year: '2-digit',
                month: '2-digit',
                day: '2-digit'
              };
              return new Date(value).toLocaleDateString('de-DE', options)
            }
          },
          {key: "user", label: "Nutzer"},
          {key: "text", label: "Text"},
        ],
      }
    },
    methods: {
      getTimeFromDate(time) {
        let date = new Date(time);
        return date.toLocaleTimeString('de-DE', {
          hour12: false,
          hour: "numeric",
          minute: "numeric"
        });
      },
      addNewMessage(value, event) {
        this.alarm.msgs.push({
          time: new Date(),
          text: this.newMessage,
          user: this.$root.userName
        });
        this.$root.logEvent(`eine Nachricht im Tagebuch zu Einsatz ${this.alarm.id} ergänzt: ${this.newMessage}`);
        this.$root.handleUpdate()
        this.newMessage = "";
      },
      changed(value) {
        this.$root.handleUpdate();
      },
      setPrio(prio) {
        console.log("Prio: " + prio);
        this.alarm.prio = prio;
        this.$root.logEvent(`Einsatz ${this.alarm.id} auf Priorität ${prio} geändert.`);
        this.$root.handleUpdate()
      },
      getPrioClass() {
        if (this.alarm.finished)
          return "prio_finished";

        if (this.alarm.prio)
          return "prio_" + this.alarm.prio;
      },
      finished(fin_val) {
        this.alarm.finished = fin_val;
        if (fin_val) {
          this.$root.logEvent(`Einsatz ${this.alarm.id} beendet.`);
        } else {
          this.$root.logEvent(`Einsatz ${this.alarm.id} wieder gestartet.`);
        }
        this.$root.handleUpdate()
      }
    },
    computed: {
      alarm: function () {
        console.log("A-ID: " + this.$route.params.id)
        let alarm = this.$root.alarms.filter(a => a.id === this.$route.params.id)[0];
        console.log(JSON.stringify(alarm));
        return alarm;
      },
      center: function () {
        return L.latLng(this.alarm.lat, this.alarm.lon)
      },
      marker: function () {
        return L.latLng(this.alarm.lat, this.alarm.lon)
      }
    }
  }
</script>

<style scoped>
    .prio_wichtig {
        background: #fdd;
    }

    .prio_gering {
        background: #ddd;
    }


    .prio_finished {
        background: #666;
        color: whitesmoke;
    }

    td {
        white-space: pre-line;
    }
</style>