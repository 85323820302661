<template>
    <div>
        <b-row>
            <b-col>
                {{item.raw.address? item.raw.address.road : ""}} {{item.raw.address? item.raw.address.house_number : ""}}
            </b-col>
            <b-col><span v-bind:style="{ transform: 'rotate(' + (137 + item.bearing) + 'deg)'}"
                         class="oi oi-location"></span><template if="item.distance">{{item.distance.toFixed(1)}} km</template>
                <!-- {{item.bearing - 180}} ° -->
            </b-col>
        </b-row>
        <b-row>
            <b-col><small>{{item.raw.address.town || item.raw.address.village}}</small></b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        props: {
            item: { required: true },
            searchText: { required: true }
        }
    }
</script>