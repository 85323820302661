<template>
    <div class="container-fluid p-0 fixed-top fixed-bottom">
        <l-map
                :zoom="zoom"
                :center="center"
                style="height: 100%"
                @dblclick="manualAlarm">
            <l-tile-layer
                    :url="url"
                    :attribution="attribution"/>
            <l-marker draggable @dragend="updateAlarmPosition(alarm, $event)" v-for="alarm in this.alarms"
                      v-bind:key="alarm.id" :icon="icon" :lat-lng="getLatLon(alarm)">
                <l-icon :icon-anchor="staticAnchor">
                    <div style="pointer-events: none; width: 200px;"><img style="pointer-events: auto;" :src="getMapIconForAlarm(alarm)" width="48px"/>
                        <div v-if="!alarm.finished" :class="alarm.prio"
                             style="pointer-events: none; position: absolute; left: 50px; top: 5px; padding: 5px; background: #dddd; font-size: 16px; min-width: 120px; border: 2px solid black;">
                            <strong>{{ alarm.name }}</strong><br/>{{alarm.location}}
                        </div>
                    </div>
                </l-icon>
                <l-popup :options="{ minWidth: 350 }">
                    <Alarm_Tooltip v-bind:alarm="alarm"/>
                </l-popup>
            </l-marker>
            <v-geosearch :options="geosearchOptions"></v-geosearch>
        </l-map>
    </div>

</template>

<script>
  import {LMap, LTileLayer, LCircle, LCircleMarker, LMarker, LPopup, LIcon} from 'vue2-leaflet';
  import {latLng, icon} from "leaflet";
  import OfflineOSMProvider from '../../mapTools/OfflineOSMProvider';
  import VGeosearch from 'vue2-leaflet-geosearch';
  import Alarm_Tooltip from "../Alarm_Tooltip.vue";

  export default {
    name: 'app',
    data() {
      return {
        staticAnchor: [16, 64],
        customText: "Foobar",
        icon: icon({
          iconUrl: "/icon.png",
          iconSize: [64, 64],
          iconAnchor: [16, 64]
        }),
        zoom: 18,
        center: L.latLng(50.801399, 12.721770),
        url: 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        marker: L.latLng(50.801399, 12.721770),
        currentZoom: 13,
        currentCenter: L.latLng(50.801399, 12.721770),
        showParagraph: false,
        geosearchOptions: { // Important part Here
          provider: new OfflineOSMProvider({
            params: {
              bounded: 1,
              viewbox: [12.612133, 50.740237, 12.779675, 50.878865],
              polygon_geojson: 1,
            }
          })
        },
      };
    },
    components: {
      Alarm_Tooltip,
      LMap,
      LTileLayer,
      LCircleMarker,
      LCircle,
      LIcon,
      LMarker,
      LPopup,
      VGeosearch
    },
    methods: {
      getLatLon(alarm) {
        console.log(alarm.detail + " " + L.latLng(alarm.lat, alarm.lon));
        return L.latLng(alarm.lat, alarm.lon);
      },
      updateAlarmPosition(alarm, event) {
        //console.log(JSON.stringify(event))

        const latLng = event.target._latlng;
        alarm.lat = latLng.lat;
        alarm.lon = latLng.lng;
        this.$root.handleUpdate();
      },
      getAlarmColor(alarm) {
        if (alarm.finished)
          return '#0f0';

        switch (alarm.prio) {
          case "wichtig":
            return '#f00';
          case "normal":
            return '#00f'
          case "gering":
            return '#ccc'
        }

        return '#000'
      },
      manualAlarm(event) {
        console.log(event);
        this.$router.push({name: 'newAlarmWithCoords', params: {lat: event.lat, lon: event.lon}})
      },
      showLongText() {
        this.showParagraph = !this.showParagraph;
      },
      popupClick() {
        alert('Popup Click!');
      },
      getMapIconForAlarm(alarm) {
        if(alarm.finished)
          return '/icon_done.png'
        else if(alarm.cars.length > 0) {
          return '/icon_active.png';
        } else {
          return '/icon_todo.png'
        }
      },
    },
    mounted() {
      console.log('mounted');
      if (this.$route.params.lat && this.$route.params.lon) {
        this.center = L.latLng(this.$route.params.lat, this.$route.params.lon)
      }
    },
    computed: {
      activeAlarms: function () {
        const prio_order = ["wichtig", "normal", "gering"]

        return this.alarms
          .filter(function (a) {
            return !a.finished
          })
          .sort((a, b) => {
            return prio_order.indexOf(a.prio) - prio_order.indexOf(b.prio)
          });
      },
      finishedAlarms: function () {
        return this.alarms.filter(function (a) {
          return a.finished
        })
      },
      alarms: function () {
        return this.$root.alarms
      },
    }
  }
</script>

<style lang="less">
    @import "~leaflet/dist/leaflet.css";
    @import "~leaflet-geosearch/assets/css/leaflet.css";
</style>

<style scoped>
    .wichtig {
        background: #fddd !important;
    }
    .normal {
        background: #fffd !important;
    }
    .gering {
        background: #dddd !important;
    }
</style>
