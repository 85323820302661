<template>
    <b-container fluid position-relative class="p-4 vh-100">
        <div class="card m-3 ">
            <div class="card-body">
                <div class="card-title">
                    <h3>Chat</h3>
                    <hr>
                </div>
                <!--<b-table :items="messages">
                    <col key="user" style="width: 120px" />
                </b-table>-->
                <div class="card-body overflow-auto " style="height: 500px;">
                    <div class="messages" v-for="(msg, index) in messages" :key="index">
                        <span class="user font-weight-bold">{{ msg.user }}: </span> {{msg.message}}<!-- <span v-html="msg.message"></span>-->
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <form @submit.prevent="sendMessage">
                    <div class="gorm-group pb-3">
                        <label for="message">Nachricht</label>
                        <input id="message" type="text" v-model="message" class="form-control">
                    </div>
                    <button type="submit" class="btn btn-success">Senden</button>
                </form>
            </div>
        </div>
    </b-container>
</template>

<script>
  export default {
    data() {
      return {
        user: '',
        message: '',
        messages: [],
        socket: this.$root.socket
      }
    },
    methods: {
      sendMessage(e) {
        e.preventDefault();

        this.socket.emit('SEND_MESSAGE', {
          user: this.$root.userName,
          message: this.message
        });
        this.message = ''
      }
    },
    mounted() {
      this.socket.emit('CHAT_CONNECT', {
        user: this.$root.userName
      });

      this.socket.on('CHAT_WELCOME', (data) => {
        this.messages = data;
        console.log("WELCOME!")
        this.socket.removeListener('CHAT_WELCOME')
      });

      this.socket.on('MESSAGE', (data) => {
        this.messages = [...this.messages, data];
        // you can also do this.messages.push(data)
      });
    }
  }
</script>

<style scoped>
</style>
