<template>
    <b-navbar fixed="top" toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand href="/alarms">Sonderlage<span class="ml-2 badge badge-warning">Beta</span></b-navbar-brand>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <b-navbar-nav class="mr-auto">
                <b-nav-item to="/alarms" class="ml-2" active-class="active">
                    Einsätze <b-badge pill variant="light">{{this.$root.activeAlarms.length}}</b-badge>
                </b-nav-item>
                <b-nav-item to="/map" active-class="active">
                    Karte
                </b-nav-item>
                <b-nav-item to="/contacts" active-class="active">
                    Kontakte
                </b-nav-item>
                <b-nav-item to="/history" active-class="active">
                    Verlauf
                </b-nav-item>

                <b-nav-item to="/chat" class="ml-5" active-class="active">
                    Chat
                </b-nav-item>

                <b-nav-item to="/todo" class="ml-5" active-class="active">
                    Vorschläge/Probleme
                </b-nav-item>
                <b-nav-item to="/help" active-class="active">
                    Hilfe
                </b-nav-item>
                <b-nav-item href="https://fwtools.org/impressum" class="ml-5" active-class="active">
                    Impressum
                </b-nav-item>
            </b-navbar-nav>
            <div class="text-light pr-5" v-on:click="setUser">{{this.$root.connectMsg}}</div>
            <b-btn variant="success" to="createAlarm"><span class="oi oi-plus"></span> Neuer Einsatz</b-btn>
        </div>
    </b-navbar>
</template>

<script>
  export default {
    props: ['page'],
    name: "NavBar",
    methods: {
      setUser() {
        this.$emit('setUser');
      }
    },
  }
</script>

<style scoped>

</style>