<template>
    <b-button-group class="w-100">
        <b-button :pressed.sync="isActive" v-on:mouseover="isActive = true" v-on:mouseout="isActive = false" variant="dark"
                  class="col-sm-3">
            {{car.home}}
        </b-button>
        <b-button :pressed.sync="isActive" v-on:mouseover="isActive = true" v-on:mouseout="isActive = false"
                  variant="primary">
            {{car.callname}}
        </b-button>
        <b-button :pressed.sync="isActive" v-on:mouseover="isActive = true" v-on:mouseout="isActive = false"
                  variant="secondary" class="col-sm-4">{{car.name}}
        </b-button>
        <b-button v-if="car.status != 2" :pressed.sync="isActive" v-on:mouseover="isActive = true"
                  v-on:mouseout="isActive = false" variant="danger" class="col-sm-1" @click="removeCar">
            <strong>X</strong></b-button>
    </b-button-group>
</template>

<script>
  export default {
    props: ['car'],
    data() {
      return {
        isActive: false,
      }
    },
    methods: {
      removeCar() {
        this.$emit('remove_car', this.car);
      }
    }
  }
</script>

<style>

</style>