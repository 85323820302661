import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.config.productionTip = false

import L from 'leaflet';
import * as Vue2Leaflet from 'vue2-leaflet'
import VueDraggable from 'vue-draggable'
import VueCookies from 'vue-cookies'
import Autocomplete from 'v-autocomplete'
import 'v-autocomplete/dist/v-autocomplete.css'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import io from 'socket.io-client';

import AlarmPage from './components/pages/AlarmPage.vue'
import MapPage from './components/pages/MapPage.vue'
import ContactPage from './components/pages/ContactPage.vue'
import HistoryPage from './components/pages/HistoryPage.vue'
import ToDoPage from './components/pages/ToDoPage.vue'
import CreateAlarmPage from "./components/pages/CreateAlarmPage";
import AlarmDetailPage from "./components/pages/AlarmDetailPage";
import HelpPage from "./components/pages/HelpPage";
import ChatPage from "./components/pages/ChatPage";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.component('l-map', Vue2Leaflet.LMap);
Vue.component('l-tilelayer', Vue2Leaflet.LTileLayer);
Vue.component('l-marker', Vue2Leaflet.LMarker);
Vue.component('l-tooltip', Vue2Leaflet.LTooltip);
Vue.component('l-popup', Vue2Leaflet.LPopup);
Vue.component('l-control-zoom', Vue2Leaflet.LControlZoom);
Vue.component('l-geo-json', Vue2Leaflet.LGeoJson);
Vue.component('l-feature-group', Vue2Leaflet.LFeatureGroup);

Vue.use(BootstrapVue);
Vue.use(VueDraggable);
Vue.use(Autocomplete);
Vue.use(VueRouter)
Vue.use(VueCookies)

import Unicon from 'vue-unicons'
import {uniClipboardNotes, uniBell, uniPhone, uniFileAlt, uniNoEntry} from 'vue-unicons/src/icons'

Unicon.add([uniClipboardNotes, uniBell, uniPhone, uniFileAlt, uniNoEntry])
Vue.use(Unicon)

VueCookies.config('2y')

const routes = [{
  path: '/alarms',
  alias: '/',
  component: AlarmPage
},
  {
    path: '/map',
    component: MapPage
  },
  {
    name: 'mapWithCoords',
    path: '/map/:lat/:lon',
    component: MapPage
  },
  {
    path: '/contacts',
    component: ContactPage
  },
  {
    path: '/history',
    component: HistoryPage
  },
  {
    path: '/todo',
    component: ToDoPage
  },
  {
    path: '/help',
    component: HelpPage
  },
  {
    path: '/createAlarm',
    component: CreateAlarmPage
  },
  {
    name: 'newAlarmWithCoords',
    path: '/createAlarm/:lat/:lon',
    component: CreateAlarmPage
  },
  {
    name: 'alarmDetail',
    path: '/alarmDetail/:id',
    component: AlarmDetailPage
  },
  {
    name: 'chat',
    path: '/chat',
    component: ChatPage
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
});

new Vue({
  data: {
    alarms: [],
    orgs: [],
    log: [],
    userName: "Unbekannt",
    alarm_settings: {
      sort_by: "numbers",
      sort_priority: true,
      compact: true
    },
    socket: io(location.hostname + ':38112'),
  },
  methods: {
    createAlarm(alarm) {
      this.manAlarmEvent = undefined;
      alarm.msgs.push({
        user: this.userName,
        text: "Alarm erstellt",
        time: new Date()
      })
      this.logEvent(this.userName + " hat Einsatz " + alarm.id + " angelegt")
      this.socket.emit('CREATE_ALARM', alarm);
      this.$router.push({path: 'alarms'});
    },
    removeCar(car, alarm) {
      car.status = 2;
      this.orgs.filter(org => org.id === car.home)[0].cars.push(car);
      alarm.cars = alarm.cars.filter(c => !(c.home === car.home && c.name === car.name)); //or splicing?
      alarm.msgs.push({
        user: this.userName,
        text: car.name + " (" + car.home + ") entfernt",
        time: new Date()
      });
      this.logEvent(`${car.name} (${car.home}) von Einsatz ${this.alarm.id} entfernt.`)
      this.handleUpdate();
    },
    logEvent(msg) {
      console.log(this.userName + ": " + msg)
      this.log.push({
        time: new Date(),
        userName: this.userName,
        msg: msg
      })
    },
    updateAlarmPosition(alarm, event) {
      //console.log(JSON.stringify(event))

      const latLng = event.target._latlng;
      alarm.lat = latLng.lat;
      alarm.lon = latLng.lng;
      this.handleUpdate();
    },
    handleUpdate() {
      console.log('Update incomming!');
      this.socket.emit('SEND_UPDATE', {alarms: this.alarms, orgs: this.orgs, log: this.log});
    },
  },
  mounted: function () {
    this.socket.on('ALARM', (data) => {
      this.alarms = [...this.alarms, data];
      // you can also do this.messages.push(data)
    });

    this.socket.on('UPDATE', (data) => {
      console.log('Incomming Websocket update!');
      if (data) {
        this.alarms = data.alarms;
        this.orgs = data.orgs;
        this.log = data.log;
      }
    });

    this.socket.emit('INIT');
  },
  computed: {
    activeAlarms: function () {
      const prio_order = ["wichtig", "normal", "gering"]

      return this.alarms
        .filter(function (a) {
          return !a.finished
        })
        .sort((a, b) => {
          let prio_a = prio_order.indexOf(a.prio);
          let prio_b = prio_order.indexOf(b.prio);
          if (this.alarm_settings.sort_by === "numbers_desc") {
            if(this.alarm_settings.sort_priority === true && prio_a !== prio_b)
              return prio_a - prio_b
            else
              return b.id - a.id;
          } else {
            if(this.alarm_settings.sort_priority === true && prio_a !== prio_b)
              return prio_a - prio_b
            else
              return a.id - b.id;
          }
        });
    },
    finishedAlarms: function () {
      return this.alarms.filter(function (a) {
        return a.finished
      })
    },
    connectMsg: function () {
      return "Verbunden: " + this.userName + "@" + location.hostname;
    }
  },
  render: h => h(App),
  router
}).$mount('#app')
