<template>
    <b-container fluid position-relative class="p-4 h-100">
        <b-form>
            <b-row>
                <b-col>
                    <h3>Neuer Alarm:</h3>
                    <b-form-group id="exampleInputGroup3"
                                  label="Einsatztyp:"
                                  label-for="exampleInput3">
                        <b-form-select id="exampleInput3"
                                       :options="etypes"
                                       required
                                       v-model="alarm.name">
                        </b-form-select>
                    </b-form-group>
                    <b-form-group id="addressInputGroup"
                                  label="E-Ort:"
                                  label-for="exampleInput1">
                        <v-autocomplete :items="results" v-model="currentResult" :get-label="getLabel" :component-item='template'
                                        :auto-select-one-item="false" @update-items="newSearch"
                                        v-on:item-clicked="setNewLocation(currentResult)">
                        </v-autocomplete>
                        <b-row class="mt-2">
                            <b-col>
                                <b-form-input id="latInput1"
                                              type="text"
                                              v-model="alarm.lat"
                                              disabled
                                              placeholder="LAT">
                                </b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-input id="lonInput1"
                                              type="text"
                                              disabled
                                              v-model="alarm.lon"
                                              placeholder="LONG">
                                </b-form-input>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group id="exampleInputGroup5"
                                  label="Priorität:"
                                  label-for="exampleInput5">
                        <b-form-select id="exampleInput5"
                                       :options="prios"
                                       required
                                       v-model="alarm.prio">
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="exampleInputGroup2"
                                  label="Detail"
                                  label-for="detailInput">
                        <b-form-input id="detailInput"
                                      type="text"
                                      v-model="alarm.detail">
                        </b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col>
                            <b-button class="mb-2" :block=true variant="success" v-on:click="createAlarm">Einsatz
                                anlegen
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="mb-2 mr-2 h-100">
                    <b-row class="m-2">
                        <l-map ref="resultMap"
                               :zoom="zoom"
                               :center="center"
                               style="height: 500px"
                               @dblclick="mapClicked">
                            <l-tile-layer
                                    :url="url"
                                    :attribution="attribution"/>
                            <l-marker :lat-lng="marker">
                            </l-marker>
                        </l-map>
                    </b-row>
                </b-col>
            </b-row>


        </b-form>
    </b-container>

</template>

<script>
  import OfflineOSMProvider from '../../mapTools/OfflineOSMProvider';
  import {OpenStreetMapProvider} from 'leaflet-geosearch';
  import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet';
  import SearchResultTemplate from "./SearchResultTemplate";

  const {extractWithQuery} = require("osm-extractor");

  //const provider = new OfflineOSMProvider({
  const provider = new OpenStreetMapProvider({
    params: {
      zoom: 16,
      bounded: 1,
      viewbox: [12.612133, 50.740237, 12.779675, 50.878865],
      polygon_geojson: 1,
      dedupe: 1,
      addressdetails: 1
    }
  });

  export default {
    components: {
      LMap,
      LTileLayer,
      LMarker,
    },
    props: ['manAlarmEvent'],
    data() {
      return {
        search: "",
        results: [],
        zoom: 18,
        center: L.latLng(50.801399, 12.721770),
        url: 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png',
        //url: 'http://192.168.122.146/hot/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        marker: L.latLng(50.801399, 12.721770),
        currentZoom: 13,
        currentCenter: L.latLng(50.801399, 12.721770),
        alarm: {
          name: 'TH klein',
          detail: '',
          location: '',
          cars: [],
          msgs: [],
          prio: "normal"
        },
        etypes: [
          {text: 'Einsatztyp wählen', value: null},
          'Brand klein', 'Brand mittel', 'Brand groß', "TH klein", "TH mittel", "TH groß", "BMA", "Türnotöffnung"
        ],
        prios: [
          "wichtig", "normal", "gering"
        ],
        name: '',
        location: '',
        currentLayer: undefined,
        currentResult: null,
        template: SearchResultTemplate
      }
    },
    methods: {
      mapClicked(event) {
        console.log("Map clicked: " + event.latlng);
        this.alarm.location = "";
        this.alarm.lat = event.latlng.lat;
        this.alarm.lon = event.latlng.lng;

        delete this.alarm.address;

        const map = this.$refs.resultMap.mapObject;
        map.panTo(event.latlng);
        this.results = [];

        this.marker = event.latlng;
        this.search = '';
      },
      showLocationOnMap(result) {
        const map = this.$refs.resultMap.mapObject;
        if (this.currentLayer && map.hasLayer(this.currentLayer)) {
          map.removeLayer(this.currentLayer);
        }
        this.currentLayer = new L.GeoJSON(result.raw.geojson);
        map.addLayer(this.currentLayer);
        this.marker = L.latLng(result.raw.lat, result.raw.lon);
        map.fitBounds(result.bounds, {
          padding: [10, 10]
        });

        map.panTo(this.marker);
      },
      setNewLocation(result) {
        this.currentResult = result;
        this.alarm.location = this.getLabel(result);
        this.alarm.lat = result.raw.lat;
        this.alarm.lon = result.raw.lon;
        this.alarm.address = result.raw.address;

        this.showLocationOnMap(result);
      },
      clearAlarm(e) {
        this.alarm.location = '';
        this.alarm.detail = '';
        delete this.alarm.detail;
        this.search = '';
        this.currentResult = {raw: {}};
      },
      createAlarm(e) {
        e.preventDefault();

        this.alarm.begin = new Date().getTime();
        this.$root.createAlarm(this.alarm);

        this.clearAlarm(e);
      },
      getLabel(item) {
        if(item === null || item.raw === undefined || item.raw.address === undefined) return "";
        else return item.raw.address.road + " " + (item.raw.address.house_number || "") + ", " + (item.raw.address.town || item.raw.address.village);
      },
      geoSearch(value, event) {
        provider.search({query: value}).then(res => {
          this.results = res;
          res.forEach(res => {
            res.distance = getDistanceFromLatLonInKm(res.y, res.x, 50.80250388156874, 12.7289558256777);
            res.bearing = bearing(res.y, res.x, 50.80250388156874, 12.7289558256777);
            console.log(JSON.stringify(res));
          });
          res = res.sort((a, b) => a.distance < b.distance);
          if (this.results.length >= 1) {
            this.setNewLocation(this.results[0]);
          } else {
            if (this.currentLayer) {
              console.log("no results.");
              const map = this.$refs.resultMap.mapObject;
              if (map.hasLayer(this.currentLayer))
                this.currentLayer.removeFrom(map);
              delete this.currentLayer;
            }
          }
          console.log(res);
        });
        return value
      },
      newSearch(value) {
        provider.search({query: value}).then(res => {
          this.results = res;
          res.forEach(res => {
            res.distance = getDistanceFromLatLonInKm(res.y, res.x, 50.80250388156874, 12.7289558256777);
            res.bearing = bearing(res.y, res.x, 50.80250388156874, 12.7289558256777);
            //console.log(JSON.stringify(res));
          });
          res = res.sort((a, b) => a.distance < b.distance);
          if (this.results.length >= 1) {
            //this.setNewLocation(this.results[0]);
          } else {
            if (this.currentLayer) {
              console.log("no results.");
              const map = this.$refs.resultMap.mapObject;
              if (map.hasLayer(currentLayer))
                this.currentLayer.removeFrom(map);
              delete this.currentLayer;
            }
          }
          console.log(res);
          return value;
        });
      },
      overpassSearch(value, event) {
        if (value.length < 4) return value;
        const query = `
            [out:json][timeout:25];
            way[highway]["name"~"${value}", i](50.740237,12.612133, 50.878865, 12.779675);
            out body;
        `;
        extractWithQuery(query).then(data =>
          console.log(data)
        );
        return value;
      }
    },
    mounted() {
      if (this.manAlarmEvent !== undefined) {
        this.mapClicked(this.manAlarmEvent)
      }
    }
  }

  function bearing(lat1, lng1, lat2, lng2) {
    var dLon = (lng1 - lng2);
    var y = Math.sin(dLon) * Math.cos(lat2);
    var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    var brng = rad2deg(Math.atan2(y, x));
    return 360 - ((brng + 360) % 360);
  }

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  function rad2deg(rad) {
    return rad * 180 / Math.PI;
  }
</script>

<style lang="stylus">
    .v-autocomplete
        .v-autocomplete-input-group
            .v-autocomplete-input
                font-size 1.5em
                padding 10px 15px
                box-shadow none
                border 1px solid #157977
                width calc(100% - 32px)
                outline none
                background-color #eee

                input
                    width: 100%
                    border-radius: 5px


            &.v-autocomplete-selected
                .v-autocomplete-input
                    color green
                    background-color #f2fff2

        .v-autocomplete-list
            z-index 999
            width 100%
            text-align left
            border none
            border-top none
            max-height 400px
            overflow-y auto
            border-bottom 1px solid #157977

            .v-autocomplete-list-item
                cursor pointer
                overflow hidden
                background-color #fff
                padding 10px
                border-bottom 1px solid #157977
                border-left 1px solid #157977
                border-right 1px solid #157977

                &:last-child
                    border-bottom none

                &:hover
                    background-color #eee

                abbr
                    opacity 0.8
                    font-size 0.8em
                    display block
                    font-family sans-serif
</style>

<style scoped>
    .active {
        background: darkred !important;
    }
</style>