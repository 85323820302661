<template>
    <div class="card m-0 p-0" :class="getPrioClass()">
        <div class="card-header">
            <b-row>
                <h3><span class="mr-3 badge badge-dark">#{{alarm.id}}</span>{{alarm.name}}</h3>
            </b-row>
        </div>
        <div class="card-body pb-1">
            <dl class="row m-0 p-0">

                <dt class="col-sm-4 p-1">Ort:</dt>
                <dd class="col-sm-8 p-1">{{alarm.location}}</dd>

                <dt class="col-sm-4 p-1">Meldung:</dt>
                <dd class="col-sm-8 p-1">{{alarm.detail}}</dd>
                <dt class="col-sm-4 p-1">Startzeit:</dt>
                <dd class="col-sm-8 p-1">{{getTimeFromDate(alarm.begin)}}</dd>
                <!--<dt class="col-sm-3 p-1">Nummer</dt>
                <dd class="col-sm-9 p-1">{{alarm.id}}</dd>-->

                <!--
                <dt class="col-sm-3 p-1">Verlauf</dt>
                <dd class="col-sm-9 p-1">
                    <dl class="row">
                        <template v-for="msg in alarm.msgs">
                            <dt class="col-sm-3">{{getTimeFromDate(msg.time)}}</dt>
                            <dl class="col-sm-9">{{msg.text}}</dl>
                        </template>
                    </dl>
                </dd>
                -->
            </dl>
        </div>
        <b-card-footer>
            <b-button-group class="w-100">
                <b-button class="col-12" variant="primary" :to="{name: 'alarmDetail', params: { id: alarm.id } }"><i class="oi oi-document"></i> Details
                </b-button>
            </b-button-group>
        </b-card-footer>
    </div>
</template>

<script>
  import Unit from './Unit.vue'
  import {Drag, Drop} from 'vue-drag-drop';
  import io from 'socket.io-client';

  export default {
    props: ['alarm'],
    components: {
      Unit, Drag, Drop
    },
    data() {
      return {
        options: {
          // dropzoneSelector: 'ul',
          // draggableSelector: 'li',
          // excludeOlderBrowsers: true,
          // showDropzoneAreas: true,
          // multipleDropzonesItemsDraggingEnabled: true,
          // onDrop(event) {},
          //onDragstart(event) {
          //   event.stop();
          //},
          onDragend(event) {
            //    alert(JSON.stringify(event))
          },
          onDrop(event) {
            //   alert(JSON.stringify(event))
          }
        },
        selected: 'radio1',
        btnOptions: [
          {text: 'E', value: 'radio1'},
          {text: 'Z', value: 'radio2'},
          {text: 'A', value: 'radio3'}
        ]
      }
    },
    methods: {
      getTimeFromDate(time) {
        let date = new Date(time);
        return date.toLocaleTimeString('de-DE', {
          hour12: false,
          hour: "numeric",
          minute: "numeric"
        });
      },
      getPrioClass() {
        if (this.alarm.finished)
          return "prio_finished";

        if (this.alarm.prio)
          return "prio_" + this.alarm.prio;
      },
    },
  }
</script>

<style scoped>
    @import "../../node_modules/open-iconic/font/css/open-iconic-bootstrap.css";

    .drag-wrapper {
        display: flex;
        justify-content: center;
    }

    ul {
        display: flex;
        flex-direction: column;
        padding: 3px !important;
        min-height: 30px;
        width: 100%;
        float: left;
        list-style-type: none;
        overflow-y: auto;
        border: 2px solid #888;
        border-radius: 0.2em;
        background: #eee;
        color: #555;
        margin-right: 5px;
    }

    /* drop target state */
    ul[aria-dropeffect="move"] {
        border-color: #68b;
        background: #fff;
    }

    /* drop target focus and dragover state */
    ul[aria-dropeffect="move"]:focus,
    ul[aria-dropeffect="move"].dragover {
        outline: none;
        box-shadow: 0 0 0 1px #fff, 0 0 0 3px #68b;
    }

    /* draggable items */
    li {
        display: block;
        list-style-type: none;
        margin: 0 0 2px 0;
        padding: 0.2em 0.4em;
        border-radius: 0.2em;
        line-height: 1.3;
    }

    li:hover {
        box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
    }

    /* items focus state */
    li:focus {
        outline: none;
        box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
    }

    /* items grabbed state */
    li[aria-grabbed="true"] {
        background: #5cc1a6;
        color: #fff;
    }

    @keyframes nodeInserted {
        from {
            opacity: 0.2;
        }
        to {
            opacity: 0.8;
        }
    }

    .item-dropzone-area {
        height: 2rem;
        background: #888;
        opacity: 0.8;
        animation-duration: 0.5s;
        animation-name: nodeInserted;
    }

    .prio_wichtig {
        background: #fdd;
    }

    .prio_gering {
        background: #ddd;
    }

    .prio_finished {
        background: #999;
        color: whitesmoke;
    }

    .cars_empty {
        font-size: 1.3em;
        font-weight: bolder;
        margin-left: 0;
        margin-right: 0;
        color: rgba(50, 50, 50, 0.3);
        min-height: 40px;
    }
</style>