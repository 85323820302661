<template>
    <div class="container-fluid position-relative h-100 p-0">
        <div class="row h-100">
            <div class="col-xs-6 col-sm-9 col-md-10 mr-0 pr-0 h-100">
                <b-tabs card fill dark>
                    <b-tab active class="p-4">
                        <template v-slot:title>
                            Aktive Einsätze <span class="badge badge-dark">{{$root.activeAlarms.length}}</span>
                        </template>
                        <div class="card-deck" v-if="$root.activeAlarms.length > 0" v-for="alarm in $root.activeAlarms">

                            <Alarm v-bind:alarm="alarm" :compact="$root.alarm_settings.compact"/>
                        </div>
                        <div class="m-5 col-md-6 col-centered " v-if="$root.activeAlarms.length === 0">
                            <div class="no-alarm mx-auto">Keine Einsätze vorhanden</div>
                            <b-button variant="success" v-on:click="page = 'new_alarm'">
                                Neuen Einsatz anlegen
                            </b-button>
                        </div>

                    </b-tab>
                    <b-tab class="p-4">
                        <template v-slot:title>
                            Abgeschlossene Einsätze <span
                                class="badge badge-dark">{{$root.finishedAlarms.length}}</span>
                        </template>
                        <div class="card-deck" v-if="$root.finishedAlarms.length > 0"
                             v-for="alarm in $root.finishedAlarms">

                            <Alarm :compact="$root.alarm_settings.compact" v-bind:alarm="alarm"/>
                        </div>
                        <div class="m-5 col-md-6 col-centered" v-if="$root.finishedAlarms.length === 0">
                            <div class="no-alarm mx-auto">Keine Einsätze abgeschlossen</div>
                        </div>
                    </b-tab>
                    <b-tab title="Einstellungen" class="bg-light p-4 h-100">
                        <b-form-group label="Sortiere Einsätze nach">
                            <b-radio-group id="sort_by" stacked v-model="$root.alarm_settings.sort_by">
                                <b-form-radio value="numbers">Nummer aufsteigend (neue Alarme unten)</b-form-radio>
                                <b-form-radio value="numbers_desc">Nummer absteigend (neue Alarme oben)</b-form-radio>
                            </b-radio-group>
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox v-model="$root.alarm_settings.sort_priority">Sortierung nach Priorität</b-form-checkbox>
                        </b-form-group>

                        <b-form-group label="Zeige Einsätze von (in Arbeit):">
                            <b-form-checkbox-group disabled stacked id="checkbox-group" v-model="selected_rics"
                                                   name="flavour-2">
                                <b-form-checkbox value="SoLa Hot">SoLa Hot</b-form-checkbox>
                                <b-form-checkbox value="SoLa Olu">SoLa Olu</b-form-checkbox>
                                <b-form-checkbox value="SoLa Ger">SoLa Ger</b-form-checkbox>
                                <b-form-checkbox value="Große Gruppe HOT">Große Gruppe HOT</b-form-checkbox>
                                <b-form-checkbox value="Kleine Gruppe HOT">Kleine Gruppe HOT</b-form-checkbox>
                                <b-form-checkbox value="Wehrleitung HOT">Wehrleitung HOT</b-form-checkbox>
                                <b-form-checkbox value="Wüstenbrand">Wüstenbrand</b-form-checkbox>
                                <b-form-checkbox value="Hüttengrund">Hüttengrund</b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                        <b-form-group label="Ansicht">
                            <b-form-checkbox v-model="$root.alarm_settings.compact">Kompakt</b-form-checkbox>
                        </b-form-group>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 bg-dark">
                <div class="pt-3 mr-3 position-fixed">
                    <Org v-for="org in $root.orgs" :key="org.name" v-bind:org="org"/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
  import Org from '../Org.vue'
  import CreateAlarm from './CreateAlarmPage.vue'
  import Alarm from '../Alarm.vue'
  import DragDropTest from '../DragDropTest.vue'
  import {LMap, LTileLayer, LCircle, LCircleMarker, LMarker, LPopup, LIcon} from 'vue2-leaflet';
  import {latLng, icon} from "leaflet";
  import VGeosearch from 'vue2-leaflet-geosearch';
  import NavBar from "../NavBar.vue";
  import io from 'socket.io-client';
  import AlarmDetail from '../pages/AlarmDetailPage.vue'
  import Alarm_Tooltip from "../Alarm_Tooltip.vue";

  export default {
    name: 'app',
    data() {
      return {
        selected_rics: ["SoLa Hot", "SoLa Olu", "SoLa Ger", "Große Gruppe HOT", "Kleine Gruppe HOT", "Wehrleitung HOT", "Wüstenbrand", "Hüttengrund"],
        staticAnchor: [16, 64],
        customText: "Foobar",
        icon: icon({
          iconUrl: "/icon.png",
          iconSize: [64, 64],
          iconAnchor: [16, 64]
        }),
        userName: "Unbekannt",
        manAlarmEvent: undefined,
        socket: io(location.hostname + ':38112'),
        connectMsg: "",
        log: [],
        log_table_fields: [
          {
            key: "time", label: "Zeit", formatter: value => {
              const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              };
              return new Date(value).toLocaleDateString('de-DE', options)
            }
          },
          {key: "userName", label: "Benutzer"},
          {key: "msg", label: "Aktion"},
        ],
        orgs: [],
        alarms: [],
        detail_alarm: undefined,
        zoom: 18,
        center: L.latLng(50.801399, 12.721770),
        url: 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png',
        //url: 'http://192.168.122.146/hot/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        marker: L.latLng(50.801399, 12.721770),
        currentZoom: 13,
        currentCenter: L.latLng(50.801399, 12.721770),
        showParagraph: false,
        page: "alarms",
        phone_list: [
          {"kontakt": "Leitstelle Zwickau", "telefon": "09999/1234567", "fax": "09999/1234569"},
          {"kontakt": "Leitstelle Chemnitz", "telefon": "09999/1234567", "fax": "09999/1234569"},
        ],
        phone_filter: "",
      };
    },
    components: {
      Org,
      Alarm,
    },
    methods: {
      getLatLon(alarm) {
        console.log(alarm.detail + " " + L.latLng(alarm.lat, alarm.lon));
        return L.latLng(alarm.lat, alarm.lon);
      },
      showAlarmOnMap(alarm) {
        this.center = this.getLatLon(alarm);
        this.page = "map";
      },
      getAlarmColor(alarm) {
        if (alarm.finished)
          return '#0f0';

        switch (alarm.prio) {
          case "wichtig":
            return '#f00';
          case "normal":
            return '#00f'
          case "gering":
            return '#ccc'
        }

        return '#000'
      },
      showLongText() {
        this.showParagraph = !this.showParagraph;
      },
      getMapIconForAlarm(alarm) {
        switch (alarm.prio) {
          case "normal":
            return undefined;
        }
      },

    },

  }
</script>

<style scoped>
    .no-alarm {
        font-size: 40px;
        font-weight: bolder;
        color: gray;
    }

</style>
