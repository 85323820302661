<template>
    <div id="app">
        <NavBar @setUser="showUserModal"/>
        <router-view style="margin-top: 56px;"/>
        <b-modal ok-only @ok="setUserName" id="modal-login" title="Name?">
            <p>Bitte gib einen Nutzernamen an:</p>
            <b-form-input v-model="userName"/>
            <p class="mt-2 text-secondary">Der Nutzername kann später jederzeit oben rechts mit Klick auf "Verbunden" geändert werden.</p>
        </b-modal>
        <b-modal ok-only @ok="accepted_welcome" id="modal-welcome" title="Sonderlage-Test">
            <h1>Willkommen</h1>
            <p>Dies ist eine Webseite zur Verwaltung einer Feuerwehr-Sonderlage.</p>
            <p>Aktuell befindet sich das Projekt noch in der Entwicklung. Die jetzt folgende Seite stellt dabei den
                aktuellen Entwicklungsstand dar, sie ist <b>noch nicht für die Nutzung mit realen Einsätzen vorgesehen.</b>
                Es können beliebige fiktive Einsätze angelegt und alle Funktionen getestet werden.
            </p>

            <p>Mehr Informationen zur Bedienung finden sich auf der Unterseite
                <router-link to="help">Hilfe</router-link>. Viel Spaß beim Ausprobieren!
            </p>
            <p>
                Bei Problemen, Bugs oder Verbesserungsvorschlägen freue ich mich jederzeit über eine Rückmeldung:
            </p>
            <b-button class="mr-3" href="mailto:sonderlage@fwtools.org">Kontakt</b-button>
            <b-button href="https://fwtools.org/impressum">Impressum</b-button>

            <div class="bg-warning rounded-lg p-3 mt-3" v-if="window.width < 1600 || window.height < 800"><b>Warnung!</b><br/> Geringe Auflösung erkannt({{window.width}}x{{window.height}}). Die Webseite ist bisher
                nicht für kleine Auflösungen optimiert und hat eventuell einige Darstellungsprobleme.
            </div>

            <div class="bg-warning rounded-lg p-3 mt-3"><b>Cookies</b><br/>Diese Webseite nutzt Cookies zum Speichern von Nutzereinstellungen. Mit dem Klick auf OK erklärst du dich damit einverstanden.
            </div>
        </b-modal>
    </div>
</template>

<script>
  import NavBar from "./components/NavBar";

  export default {
    name: 'app',
    data() {
      return {
        userName: "",
        window: {
          width: 0,
          height: 0
        }
      }
    },
    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize();
    }
    ,
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    }
    ,
    methods: {
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      },
      setUserName() {
        this.$cookies.set("user", this.userName)
        this.$root.userName = this.userName;
      },
      showUserModal() {
        this.$bvModal.show("modal-login");
      },
      accepted_welcome(){
        this.$cookies.set("welcome_seen", true);
      }
    },
    components: {
      NavBar
    },
    mounted() {
      if (this.$cookies.isKey("user"))
        this.$root.userName = this.$cookies.get("user");
      else
        this.showUserModal();

      if (!this.$cookies.isKey("welcome_seen"))
          this.$bvModal.show("modal-welcome");
    },
  }
</script>

<style scoped>

</style>
