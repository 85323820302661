<template>
    <drop class="drop list" @drop="handleDrop(org, ...arguments)">
        <div class="card mt-3 bg-secondary text-light">
            <div class="card-body p-2">
                <div class="card-title m-0">
                    <h3 class="m-0">{{org.name}}</h3>
                </div>
            </div>
            <div class="card-footer bg-light">
                <div class="container-fluid">
                    <div class="row rounded p-1">
                        <div class="cars_empty" v-if="org.cars.length === 0">
                            Hier Fahrzeuge ablegen
                        </div>
                        <drag v-for="car in org.cars"
                              class="drag col-12 p-1"
                              :key="car.name"
                              :class="{ [car]: true }"
                              :transfer-data="{ item: car, alarm: org, example: 'alarm.cars' }">
                            <Unit v-bind:car="car"/>
                        </drag>
                    </div>
                </div>
            </div>
        </div>
    </drop>

</template>

<script>
    import Unit from './Unit.vue'
    import {Drag, Drop} from 'vue-drag-drop';
    import io from 'socket.io-client';

    export default {
        props: ['org'],
        components: {
            Unit, Drag, Drop
        },
        data() {
            return {
                options: {
                    // dropzoneSelector: 'ul',
                    // draggableSelector: 'li',
                    // excludeOlderBrowsers: true,
                    // showDropzoneAreas: true,
                    // multipleDropzonesItemsDraggingEnabled: true,
                    // onDrop(event) {},
                    //onDragstart(event) {
                    //   event.stop();
                    //},
                    onDragend(event) {
                        //    alert(JSON.stringify(event))
                    },
                    onDrop(event) {
                        //   alert(JSON.stringify(event))
                    }
                },
                selected: 'radio1',
                btnOptions: [
                    {text: 'E', value: 'radio1'},
                    {text: 'Z', value: 'radio2'},
                    {text: 'A', value: 'radio3'}
                ]
            }
        },
        methods: {
            handleDrop(toAlarm, data) {
                console.log(JSON.stringify(data))
                const fromAlarm = data.alarm;
                if (fromAlarm) {
                    data.item.status = 2;
                    toAlarm.cars.push(data.item);
                    fromAlarm.cars.splice(fromAlarm.cars.indexOf(data.item), 1);
                    toAlarm.msgs.push({time: new Date(), text: data.item.name + " hinzugefügt"})
                    fromAlarm.msgs.push({time: new Date(), text: data.item.name + " entfernt"})
                    toAlarm.cars.sort((a, b) => a > b);
                }
            },
            getTimeFromDate(date) {
                return date.toLocaleTimeString('de-DE', {
                    hour12: false,
                    hour: "numeric",
                    minute: "numeric"
                });
            }
        },
    }
</script>

<style scoped>
    .drag-wrapper {
        display: flex;
        justify-content: center;
    }

    ul {
        display: flex;
        flex-direction: column;
        padding: 3px !important;
        min-height: 30px;
        width: 100%;
        float: left;
        list-style-type: none;
        overflow-y: auto;
        border: 2px solid #888;
        border-radius: 0.2em;
        background: #eee;
        color: #555;
        margin-right: 5px;
    }

    /* drop target state */
    ul[aria-dropeffect="move"] {
        border-color: #68b;
        background: #fff;
    }

    /* drop target focus and dragover state */
    ul[aria-dropeffect="move"]:focus,
    ul[aria-dropeffect="move"].dragover {
        outline: none;
        box-shadow: 0 0 0 1px #fff, 0 0 0 3px #68b;
    }

    /* draggable items */
    li {
        display: block;
        list-style-type: none;
        margin: 0 0 2px 0;
        padding: 0.2em 0.4em;
        border-radius: 0.2em;
        line-height: 1.3;
    }

    li:hover {
        box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
    }

    /* items focus state */
    li:focus {
        outline: none;
        box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
    }

    /* items grabbed state */
    li[aria-grabbed="true"] {
        background: #5cc1a6;
        color: #fff;
    }

    @keyframes nodeInserted {
        from {
            opacity: 0.2;
        }
        to {
            opacity: 0.8;
        }
    }

    .item-dropzone-area {
        height: 2rem;
        background: #888;
        opacity: 0.8;
        animation-duration: 0.5s;
        animation-name: nodeInserted;
    }

    .cars_empty {
        font-size: 1.3em;
        font-weight: bolder;
        margin-left: 0;
        margin-right: 0;
        color: rgba(50, 50, 50, 0.3);
        min-height: 40px;
    }

</style>